import SocketIO from 'socket.io-client'
import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import { baseDomain } from '../repositories/Clients/AxiosClient'
import store from '../store'

export const initializeSocketConnection = (user) => {
  let socketConnection = SocketIO(baseDomain)

  if (user) {
    socketConnection = SocketIO(`${baseDomain}/admin`, {
      auth: {
        token: user?.token,
      },
    })
  }

  // Configurar VueSocketIO una vez que se ha establecido la conexión
  Vue.use(
    new VueSocketIO({
      debug: false,
      connection: socketConnection,
      vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_',
      },
    })
  )
}
