import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import SocketIO from 'socket.io-client'
// import VueSocketIO from 'vue-socket.io'
import VueEventBus from 'vue-plugin-event-bus'
// import { baseDomain } from './repositories/Clients/AxiosClient'
import { initializeSocketConnection } from './services/socket'

Vue.config.productionTip = false

const user = JSON.parse(localStorage.getItem('user'))

if (user) {
  // Si el usuario ya está logueado, inicializa la conexión al socket
  initializeSocketConnection(user)
}

Vue.use(VueEventBus)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
