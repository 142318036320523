var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-5 mx-md-16 px-md-16"},[_c('span',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.updateList))]),_c('v-data-table',{staticClass:"elevation-1 rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.appointments.items,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.patient.cellphone",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"id":"openwhatsapp","target":"_blank","href":("https://wa.me/" + (item['patient.cellphone']))}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-whatsapp ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.selectHomeAppointment(item)}}},[_c('v-icon',{attrs:{"small":"","color":"orange"}},[_vm._v("fa-edit")])],1)]}},{key:"item.AttendedByUser",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['AttendedByUser.firstName'])+" "+_vm._s(item['AttendedByUser.lastName'])+" ")]}},{key:"item.FinishedByUser",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['FinishedByUser.firstName'])+" "+_vm._s(item['FinishedByUser.lastName'])+" ")]}}])}),_c('home-appointment-dialog',{attrs:{"homeAppointment":_vm.homeAppointment,"edit":_vm.edit,"dialog":_vm.dialog},on:{"dismiss":_vm.newItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }