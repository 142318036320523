<template>
  <div class="mx-5 mx-md-16 px-md-16">
    <span class="d-none">{{ updateList }}</span>

    <v-data-table
      :headers="headers"
      :items="appointments.items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :search="search"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:item.patient.cellphone="{ item }">
        <a
          id="openwhatsapp"
          target="_blank"
          :href="`https://wa.me/${item['patient.cellphone']}`"
        >
          <v-icon color="green"> mdi-whatsapp </v-icon>
        </a>
      </template>
      <template v-slot:item.status="{ item }">
        {{ getStatusText(item.status) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="selectHomeAppointment(item)"
          ><v-icon small color="orange">fa-edit</v-icon></v-btn
        >
      </template>
      <template v-slot:item.AttendedByUser="{ item }">
        {{ item['AttendedByUser.firstName'] }}
        {{ item['AttendedByUser.lastName'] }}
      </template>
      <template v-slot:item.FinishedByUser="{ item }">
        {{ item['FinishedByUser.firstName'] }}
        {{ item['FinishedByUser.lastName'] }}
      </template>
    </v-data-table>
    <home-appointment-dialog
      :homeAppointment="homeAppointment"
      :edit="edit"
      :dialog="dialog"
      @dismiss="newItem"
    />
  </div>
</template>

<script>
import HomeAppointmentDialog from './HomeAppointmentDialog.vue'
import Repository from '@/repositories/RepositoryFactory'
const HomeAppointmentRepository = Repository.get('homeAppointments')
import { format } from 'date-fns'

const APPOINTMENT_STATUS = {
  pending: 'Pendiente',
  finished: 'Finalizada',
  attended: 'Atendida',
}

export default {
  components: {
    HomeAppointmentDialog,
  },
  name: 'HomeAppointmentList',
  props: ['search'],

  data: () => ({
    appointments: {},
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Fecha',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'createdAt',
      },
      {
        text: 'Cédula',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'patientId',
      },
      {
        text: 'Nombre',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'patient.firstName',
      },
      {
        text: 'Apellido',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'patient.lastName',
      },
      {
        text: 'Contacto',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'patient.cellphone',
      },
      {
        text: 'Estatus',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'status',
      },
      {
        text: 'Atendido por',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'AttendedByUser',
      },
      {
        text: 'Finalizado por',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'FinishedByUser',
      },
      {
        text: 'Opciones',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'actions',
      },
    ],
    itemsPerPages: [7, 10, 20, 30, 50, 100],
    del: true,
    edit: true,
    homeAppointment: null,
    dialog: false,
    updateList: false,
  }),
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    newItem: function () {
      this.dialog = false
      this.loadData()
    },
    selectHomeAppointment: function (homeAppointment) {
      this.homeAppointment = Object.assign({}, homeAppointment)
      this.dialog = true
    },
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await HomeAppointmentRepository.get()
        this.appointments = res.data.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    getStatusText(status) {
      return APPOINTMENT_STATUS[status]
    },
    formatDate: (value) => {
      const date = value ? new Date(value) : new Date()
      return format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
