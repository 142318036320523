import Client from './Clients/AxiosClient'
const resource = '/appointmentsConfig'

export default {
  get() {
    return Client.get(`${resource}`)
  },
  update({ id, ...config }) {
    return Client.put(`${resource}/${id}`, config)
  },
}
