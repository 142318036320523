import Client from './Clients/AxiosClient'
const resource = '/homeAppointments'

export default {
  get() {
    return Client.get(`${resource}`)
  },
  update(payload, id) {
    return Client.patch(`${resource}/${id}`, payload)
  },
}
