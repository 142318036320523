<template>
  <v-container>
    <v-card class="bg-card rounded-xl py-5 mt-5">
      <h4 class="text-center text-h4 mb-5">Historial de Sincronización</h4>

      <div
        class="
          mx-5 mx-md-8
          px-md-8
          d-flex
          flex-column flex-md-row
          align-center align-md-baseline
        "
      >
        <div style="width: 250px">
          <v-text-field
            outlined
            dense
            solo
            placeholder="Buscar"
            class="rounded-lg"
            prepend-inner-icon="fa-search"
            v-model="search"
          ></v-text-field>
        </div>

        <v-spacer />
      </div>

      <div class="mx-5 mx-md-8 px-md-8">
        <v-data-table
          :headers="headers"
          :items="syncHistories.items"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :search="search"
          hide-default-footer
          class="elevation-1 rounded-lg"
          @page-count="pageCount = $event"
        >
          <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }} {{ formatHours(item.date) }}
          </template>
        </v-data-table>
        <div
          class="pt-2 d-flex flex-column flex-md-row align-center justify-end"
        >
          <span class="font-quicksand">Filas por páginas: </span>
          <span style="width: 50px" class="mx-md-2">
            <v-select
              v-model="itemsPerPage"
              :items="itemsPerPages"
              class="font-quicksand"
            ></v-select>
          </span>
          <span class="font-quicksand"
            >1-{{ itemsPerPage }} de {{ syncHistories.totalItems }}
          </span>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="pageVisible"
            color="red"
            class="font-work-sans"
          ></v-pagination>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { format } from 'date-fns'
import Repository from '@/repositories/RepositoryFactory'

const SyncHistoryRepository = Repository.get('syncHistories')

export default {
  name: 'SyncHistories',
  data: () => ({
    search: '',
    syncHistories: {},
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'URL',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'URL',
      },
      {
        text: 'Acción',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'action',
      },
      {
        text: 'Resultado',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'result',
      },
      {
        text: 'Fecha',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'date',
      },
    ],
    itemsPerPages: [7, 10, 20, 30, 50, 100],
  }),
  computed: {
    roleUser: function () {
      return this.$store.state.auth.user?.roleId || ''
    },
  },
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await SyncHistoryRepository.get()
        this.syncHistories = res.data.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    formatDate: (value) => {
      const date = value ? new Date(value) : new Date()
      return format(date, 'dd/MM/yyyy')
    },
    formatHours: (value) => {
      const date = value ? new Date(value) : new Date()
      return date.toLocaleString('es-VE', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    },
  },
}
</script>
