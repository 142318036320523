<template>
  <div class="mx-5 mx-md-16 px-md-16">
    <span class="d-none">{{ updateList }}</span>

    <v-data-table
      :headers="headers"
      :items="appointments.items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :search="search"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.date="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ getStatusText(item.status) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
const AppointmentRepository = Repository.get('appointments')
import { format } from 'date-fns'
import Swal from 'sweetalert2'

const APPOINTMENT_STATUS = {
  pending: 'Pendiente',
  finished: 'Finalizada',
  canceled: 'Cancelada',
}

export default {
  name: 'AppointmentList',
  props: ['search', 'newItem'],
  data: () => ({
    appointments: {},
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Fecha',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'date',
      },
      {
        text: 'Hora',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'time',
      },
      {
        text: 'Cédula',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'patientId',
      },
      {
        text: 'Nombre',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'patient.firstName',
      },
      {
        text: 'Apellido',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'patient.lastName',
      },

      {
        text: 'Estatus',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'status',
      },
      {
        text: 'Atendido por',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'finishedBy',
      },
      {
        text: 'Hora de atención',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'finishedTime',
      },
    ],
    itemsPerPages: [7, 10, 20, 30, 50, 100],
    del: true,
    edit: true,
    bioanalyst: null,
    dialog: false,
    dialog2: false,
  }),
  computed: {
    updateList: async function () {
      if (this.newItem) {
        await this.loadData()
      }
    },
  },
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await AppointmentRepository.get()
        this.appointments = res.data.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }
    },
    getStatusText(status) {
      return APPOINTMENT_STATUS[status]
    },
    formatDate: (value) => {
      const date = value ? new Date(value) : new Date()
      return format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
