<template>
  <div>
    <appointments />
  </div>
</template>

<script>
import Appointments from '@/components/appointments/Appointments.vue'

export default {
  components: { Appointments },
  name: 'Appointment',
}
</script>
