<template>
  <v-container>
    <v-card class="bg-card rounded-xl py-5 mt-5">
      <h4 class="text-center text-h4 mb-5">Citas</h4>

      <div
        class="mx-5 mx-md-16 px-md-16 d-flex flex-column flex-md-row align-center align-md-baseline"
      >
        <div style="width: 250px">
          <v-text-field
            outlined
            dense
            solo
            placeholder="Buscar"
            class="rounded-lg"
            prepend-inner-icon="fa-search"
            v-model="search"
          ></v-text-field>
        </div>
      </div>

      <appointment-list :search="search" />
    </v-card>
  </v-container>
</template>

<script>
import AppointmentList from './AppointmentList.vue'
export default {
  components: {
    AppointmentList,
  },
  name: 'Appointments',
  data: () => ({
    active: true,
    search: '',
    edit: false,
    appointment: null,
    dialog: false,
    updateList: false,
  }),
  computed: {
    roleUser: function () {
      return this.$store.state.auth.user?.roleId || ''
    },
  },
  methods: {
    newItem: function () {
      this.dialog = false
      this.updateList = true

      setTimeout(() => {
        this.updateList = false
      }, 10)
    },
  },
}
</script>
