<template>
  <v-dialog
    v-model="isActive"
    persistent
    transition="dialog-transition"
    width="400"
    height="270"
  >
    <span class="d-none">{{ change }}</span>

    <v-card class="rounded-lg">
      <v-card-title>
        <v-row>
          <v-col cols="11" class="px-0">
            <h6 class="text-subtitle-1 font-weight-bold text-center">
              <span class="font-work-sans ml-5">Domicilio</span>
            </h6>
          </v-col>
          <v-col cols="1" class="pa-0">
            <v-btn color="primary" icon @click="$emit('dismiss')"
              ><v-icon small>fa-times</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <label for="" class="ml-4 black--text font-weight-medium">Cédula</label>
        <v-text-field
          placeholder="Nombre"
          color="primary"
          rounded
          prepend-inner-icon="fa-file-alt"
          readonly
          outlined
          class="mt-2 mb-n3"
          :value="homeAppointment.patientId"
        ></v-text-field>
        <label for="" class="ml-4 black--text font-weight-medium">Nombre</label>
        <v-text-field
          placeholder="Nombre"
          color="primary"
          rounded
          prepend-inner-icon="fa-file-alt"
          readonly
          outlined
          class="mt-2 mb-n3"
          :value="`${homeAppointment['patient.firstName']} ${homeAppointment['patient.lastName']}`"
        ></v-text-field>

        <label for="" class="ml-4 black--text font-weight-medium"
          >Estatus</label
        >
        <v-text-field
          placeholder="Nombre"
          color="primary"
          rounded
          prepend-inner-icon="fa-file-alt"
          readonly
          outlined
          class="mt-2 mb-n3"
          :value="getStatus(homeAppointment.status)"
        ></v-text-field>

        <label class="ml-4 black--text font-weight-medium">Examenes</label>
        <v-col>
          <a
            :key="photo"
            :href="photo"
            target="_blank"
            style="display: block"
            v-for="(photo, index) in homeAppointment.photos"
          >
            Examen {{ index + 1 }}
          </a>
        </v-col>
      </v-card-text>

      <v-card-actions class="mt-n4">
        <v-btn
          color="primary"
          outlined
          class="rounded-lg px-4"
          @click="
            () => {
              $emit('dismiss')
            }
          "
        >
          <v-icon small>fa-times</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="homeAppointment.status === 'pending'"
          color="primary"
          outlined
          class="rounded-lg px-4"
          @click="changeStatus('attended')"
        >
          <v-icon small>fa-times</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold"
            >Marcar como atendido</span
          >
        </v-btn>

        <v-btn
          v-if="homeAppointment.status === 'attended'"
          color="success"
          class="rounded-lg elevation-0 px-4"
          @click="changeStatus('finished')"
        >
          <v-icon small>fa-check</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold"
            >Marcar como finalizado</span
          >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
const HomeAppointmentRepository = Repository.get('homeAppointments')

const APPOINTMENT_STATUS = {
  pending: 'Pendiente',
  finished: 'Finalizada',
  attended: 'Atendida',
}

export default {
  name: 'HomeAppointmentDialog',
  props: {
    homeAppointment: {
      type: Object,
      default: () => ({
        patientId: '',
        'patient.firstName': '',
        'patient.lastName': '',
        status: '',
      }),
    },
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    loading: false,
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    getStatus: function (status) {
      return APPOINTMENT_STATUS[status] || ''
    },
    changeStatus: async function (status) {
      this.loading = true

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Domicilio actualizado con éxito'
          : 'Domicilio registrado con éxito',
      }

      try {
        await HomeAppointmentRepository.update(
          { status },
          this.homeAppointment.id
        )

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.data ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
