import axios from 'axios'

export const baseDomain = process.env.VUE_APP_BACKEND_URL
// export const baseDomain = 'http://localhost:4030'
// export const baseDomain = 'https://labcli-debug.herokuapp.com'

const baseURL = `${baseDomain}/api/v1` // Incase of /api/v1;

// ALL DEFUALT CONFIGURATION HERE
const user = JSON.parse(localStorage.getItem('user'))
const accessToken = user ? user.token : null

export default axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})
