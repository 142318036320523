<template>
  <v-row justify="center" style="height: 100vh; min-height: 610px">
    <v-col cols="12" class="mb-n16 mt-6">
      <v-img
        :src="require('@/assets/logo-2.png')"
        class="ma-3"
        contain
        height="110"
      />

      <h5 class="text-center text-h5 font-Releway py-5">
        Turnos | Panel administrativo
      </h5>
    </v-col>

    <v-col cols="11" sm="5" md="4" lg="3" class="">
      <v-card color="primary" class="rounded-lg">
        <h6 class="text-center text-h6 font-Releway white--text pt-3">
          Inicia sesión
        </h6>

        <v-divider dark class="mx-10 mt-3 py-2"></v-divider>

        <v-form ref="form" v-model="valid" lazy-validation class="mx-8">
          <label for="" class="ml-12 white--text">Usuario</label>
          <v-text-field
            placeholder="Usuario"
            color="black"
            rounded
            :rules="[rules.required]"
            required
            solo
            prepend-icon="$usuario"
            class="mt-2 mb-n2"
            v-model="auth.username"
          ></v-text-field>

          <label for="" class="ml-12 white--text">Contraseña</label>
          <v-text-field
            placeholder="Contraseña"
            color="black"
            rounded
            solo
            :rules="[rules.required]"
            required
            prepend-icon="$candado"
            :append-icon="show ? 'fa-eye' : 'fa-eye-slash'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            @keydown.enter="submit"
            minlength="8"
            class="mt-2"
            v-model="auth.password"
          ></v-text-field>

          <div class="text-center">
            <v-btn
              color="success"
              class="mb-5 rounded-lg text-capitalize"
              @click="submit"
              :loading="loading"
            >
              Iniciar
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Swal from 'sweetalert2'
import { initializeSocketConnection } from '../services/socket'
export default {
  name: 'FormLogin',
  data: () => ({
    valid: false,
    show: false,
    loading: false,
    auth: {
      username: '',
      password: '',
    },
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      counter: (v) => v.length >= 8 || 'Contraseña mínima de 8 carácteres.',
    },
  }),
  methods: {
    submit: async function () {
      this.$refs.form.validate()

      setTimeout(async () => {
        if (this.valid) {
          await this.login()
        }
      }, 50)
    },

    login: async function () {
      this.loading = true
      try {
        await this.$store.dispatch('auth/login', this.auth)

        const user = JSON.parse(localStorage.getItem('user'))

        await initializeSocketConnection(user)

        location.replace('/home')
      } catch (error) {
        const response = error.response ? error.response.data.message : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }
      this.loading = false
    },
  },
}
</script>
