<template>
  <v-container>
    <v-card class="bg-card rounded-xl py-5 mt-2">
      <h4 class="text-center text-h4 mb-2">Configuración de citas</h4>

      <v-row class="mx-5 mx-lg-16 px-lg-16 mt-10" justify="center">
        <v-col cols="6">
          <v-form ref="form" v-model="valid" lazy-validation>
            <label for="" class="ml-4 black--text font-weight-medium"
              >Duración del bloque (Minutos)</label
            >
            <v-text-field
              placeholder="Nombre de usuario"
              color="black"
              v-model="config.blockDurationMinutes"
              rounded
              required
              solo
              type="number"
              class="mt-2 mb-n3"
              :rules="[rules.number]"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Maximo numero de citas por bloque</label
            >
            <v-text-field
              color="black"
              v-model="config.maxAppointmentsPerBlock"
              rounded
              required
              solo
              type="number"
              class="mt-2 mb-n3"
              :rules="[rules.number]"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Hora de inicio</label
            >
            <v-text-field
              color="black"
              v-model="config.startTime"
              rounded
              required
              solo
              type="time"
              class="mt-2 mb-n3"
              :rules="[rules.required]"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Hora maxima</label
            >
            <v-text-field
              color="black"
              v-model="config.endTime"
              rounded
              required
              solo
              type="time"
              class="mt-2 mb-n3"
              :rules="[rules.required, endTimeValidation]"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Máximo de dias desde que puede pedir cita</label
            >
            <v-text-field
              color="black"
              v-model="config.maxDaysInAdvance"
              rounded
              required
              solo
              type="number"
              class="mt-2 mb-n3"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>

          <v-row class="mt-4" justify="end">
            <v-btn
              color="primary"
              class="rounded-lg my-2 my-md-0 elevation-0"
              @click="submit"
              :loading="loading"
            >
              <v-icon>fa-user-plus</v-icon>
              <span class="text-capitalize ml-2 font-weight-bold"
                >Guardar cambios</span
              >
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <alerts-cli />
  </v-container>
</template>

<script>
import AlertsCli from '@/components/Alerts.vue'
import Repository from '@/repositories/RepositoryFactory'
const AppointmentConfigRepository = Repository.get('appointmentConfig')
import Swal from 'sweetalert2'

export default {
  components: {
    AlertsCli,
  },
  name: 'AppointmentConfig',
  data: () => ({
    valid: false,
    loading: false,

    config: {
      id: '',
      blockDurationMinutes: 0,
      startTime: '',
      endTime: '',
      maxAppointmentsPerBlock: 0,
      maxDaysInAdvance: 1,
    },
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      number: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
      // endTimeValidation: (value, a) => {
      //   console.log(value, a)
      //   return true
      //   // if (!value || !this.config.startTime) {
      //   //   return true // No validar si algún campo no está lleno
      //   // }
      //   // return (
      //   //   value > this.config.startTime ||
      //   //   'La hora máxima debe ser mayor que la hora de inicio.'
      //   // )
      // },
    },
  }),
  mounted: async function () {
    try {
      const { data } = await AppointmentConfigRepository.get()

      const {
        blockDurationMinutes,
        maxAppointmentsPerBlock,
        startTime,
        endTime,
        id,
        maxDaysInAdvance,
      } = data.data

      this.config = {
        blockDurationMinutes,
        maxAppointmentsPerBlock,
        startTime,
        endTime,
        id,
        maxDaysInAdvance,
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    endTimeValidation(value) {
      if (!value || !this.config.startTime) {
        return true // No validar si algún campo no está lleno
      }
      return (
        value > this.config.startTime ||
        'La hora máxima debe ser mayor que la hora de inicio.'
      )
    },
    submit: function () {
      this.$refs.form.validate()

      setTimeout(async () => {
        if (this.valid) {
          await this.create()
        }
      }, 500)
    },
    create: async function () {
      this.loading = true

      try {
        const res = await AppointmentConfigRepository.update(this.config)

        const data = {
          type: 'success',
          title: 'Petición exitosa',
          text: res.data.message,
        }

        this.$store.dispatch('alerts/activeAlerts', data)
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
