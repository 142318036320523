<template>
  <div>
    <home-appointments />
  </div>
</template>

<script>
import HomeAppointments from '@/components/appointments/HomeAppointments.vue'

export default {
  components: { HomeAppointments },
  name: 'HomeAppointment',
}
</script>
