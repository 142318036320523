import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/admins',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import('@/components/Users/Admins/Admins.vue'),
      },
      {
        path: '/admins/new',
        name: 'NewAdmin',
        component: () => import('@/components/Users/Admins/Create.vue'),
      },
      {
        path: '/admins/edit',
        name: 'EditAdmin',
        component: () => import('@/components/Users/Admins/Edit.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/patients',
    component: () => import('../views/Patient.vue'),
    children: [
      {
        path: '',
        name: 'Patient',
        component: () => import('@/components/Users/Patients/Patients.vue'),
      },
      {
        path: '/patients/edit',
        name: 'EditPatient',
        component: () => import('@/components/Users/Patients/Edit.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/monitors',
    name: 'Monitor',
    component: () => import('../views/Monitor.vue'),
  },
  {
    path: '/cashdesks',
    name: 'CashDesk',
    component: () => import('../views/CashDesk.vue'),
  },
  {
    path: '/samplingstations',
    name: 'SamplingStation',
    component: () => import('../views/SamplingStation.vue'),
  },
  {
    path: '/printers',
    name: 'Printer',
    component: () => import('../views/Printer.vue'),
  },
  {
    path: '/categories',
    name: 'Category',
    component: () => import('../views/Category.vue'),
  },
  {
    path: '/tests',
    component: () => import('../views/Test.vue'),
    children: [
      {
        path: '',
        name: 'Test',
        component: () => import('@/components/catalog/Tests/Tests.vue'),
      },
      {
        path: '/tests/new',
        name: 'NewTest',
        component: () => import('@/components/catalog/Tests/Create.vue'),
      },
      {
        path: '/tests/edit',
        name: 'EditTest',
        component: () => import('@/components/catalog/Tests/Edit.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/indications',
    name: 'Indication',
    component: () => import('../views/Indication.vue'),
  },
  {
    path: '/details',
    name: 'Detail',
    component: () => import('../views/Detail.vue'),
  },
  {
    path: '/referenceValues',
    name: 'ReferenceValue',
    component: () => import('../views/ReferenceValue.vue'),
  },
  {
    path: '/orders',
    name: 'Order',
    component: () => import('../views/Order.vue'),
  },
  {
    path: '/bioanalysts',
    name: 'Bioanalyst',
    component: () => import('../views/Bioanalyst.vue'),
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: () => import('../views/Appointment.vue'),
  },
  {
    path: '/homeAppointment',
    name: 'HomeAppointment',
    component: () => import('../views/HomeAppointment.vue'),
  },
  {
    path: '/appointment-config',
    name: 'AppointmentConfig',
    component: () => import('../views/AppointmentConfig.vue'),
  },
  {
    path: '/results',
    name: 'Result',
    component: () => import('../views/Result.vue'),
  },
  {
    path: '/resultValues',
    name: 'ResultValue',
    component: () => import('../views/ResultValue.vue'),
  },
  // {
  //   path: '/paymentMethods',
  //   name: 'PaymentMethod',
  //   component: () => import('../views/PaymentMethod.vue'),
  // },
  {
    path: '/userlogs',
    name: 'UserLog',
    component: () => import('../views/UserLog.vue'),
  },
  {
    path: '/errorlogs',
    name: 'ErrorLog',
    component: () => import('../views/ErrorLog.vue'),
  },
  {
    path: '/syncHistories',
    name: 'SyncHistory',
    component: () => import('../views/SyncHistory.vue'),
  },
  {
    path: '/internaldb',
    name: 'InternalDatabase',
    component: () => import('../views/InternalDatabase.vue'),
  },
  {
    path: '/playlist',
    name: 'Playlist',
    component: () => import('../views/Playlist.vue'),
  },
  {
    path: '/contents',
    name: 'Content',
    component: () => import('../views/Content.vue'),
  },
  {
    path: '*',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    location.replace('/')
  } else if (!authRequired && loggedIn && publicPages.includes(to.path)) {
    location.replace('/home')
  } else {
    next()
  }
})

export default router
